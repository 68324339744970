import { Component, OnInit, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss']
})
export class SvgComponent implements OnInit {

    @Input() folder = null
    @Input() name = null
    @Input() size = '100%'

    public svgDatas:any = null

    constructor(
        public http : HttpClient,
        public sanitizer : DomSanitizer
    ) { }

    ngOnInit(): void {
        new Promise((resolve)=>{
            this.http.get('assets/img/'+this.folder+'/'+this.name+'.svg', {responseType: 'text'})
            .subscribe(
                data => resolve(data),
                err => resolve({httpError:err})
            );
        })
        .then((svgDatas:any)=>{
            var id = this.folder.slice(0,1).toUpperCase()+this.folder.slice(1).toLowerCase()+this.name.slice(0,1).toUpperCase()+this.name.slice(1).toLowerCase()
            var newDatas = svgDatas
            .replaceAll(/\"Calque..\"/gi, '"'+id+'"')
            .replaceAll(/\.cls/gi, '#'+id+' .cls')
            .replaceAll(/DameronItalic\, Dameron Italic/gi, 'Dameron;font-style: italic')




            this.svgDatas = this.sanitizer.bypassSecurityTrustHtml(newDatas)
        })
    }

}
