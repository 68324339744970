<div id="fullpage" class="animate__animated animate__fadeIn">
    <section class="section">
        <app-welcome></app-welcome>
    </section>

    <section class="section">
        <app-industry></app-industry>
    </section>

    <section class="section">
        <app-shining></app-shining>
    </section>

    <section class="section">
        <app-office></app-office>
    </section>

    <section class="section">
        <app-duality></app-duality>
    </section>

    <section class="section">
        <app-house></app-house>
    </section>

    <section class="section">
        <app-divers></app-divers>
    </section>

    <section class="section">
        <app-contact></app-contact>
    </section>
</div>
