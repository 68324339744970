<a [href]="target" target="_blank" *ngIf="clickable">
    <img
        [src]="src"
        [class]="class+' '+clazz"
        alt="img"
        [ngClass]="{'cursor-pointer' : clickable}"
    >
</a>

<img
    *ngIf="!clickable"
    [src]="src"
    [class]="class+' '+clazz"
    alt="img"
    [ngClass]="{'cursor-pointer' : clickable}"
>
