import { Component, OnInit } from '@angular/core';

import { texts } from '../../texts'

@Component({
    selector: 'app-divers',
    templateUrl: './divers.component.html',
    styleUrls: ['./divers.component.scss']
})
export class DiversComponent implements OnInit {

    public texts = texts

    constructor() { }

    ngOnInit(): void {
    }

}
