import { Component, OnInit, AfterViewInit } from '@angular/core';

declare var fullpage : any;

@Component({
    selector: 'app-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit, AfterViewInit {

    constructor() { }

    ngOnInit(): void {
    }

    ngAfterViewInit(){
        new fullpage('#fullpage', {
            slidesNavigation : true,
            menu: '#menu',
            anchors: ['welcome','industry','shining','office','duality','house','divers','contact'],
            loopHorizontal : false,
            controlArrows : true,
            paddingTop: '0px',
            paddingBottom: '0px',
            css3: true,
            scrollingSpeed: 300,
            fitToSectionDelay : 200
        });
    }

}
