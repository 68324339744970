<div class="slide">
    <div class="container">
        <div class="row">
            <div class="col-9">
                <div class="ratio ratio-16x9 rounded overflow-hidden">
                    <iframe src="https://www.youtube.com/embed/N9heJbvqUw0" title="YouTube video" frameborder="0" allowfullscreen></iframe>
                </div>

            </div>
            <div class="col-3">
                <titre
                    name="Project Office"
                    logiciel="Blender"
                ></titre>
                <div class="mb-5"></div>
                <text-bloc
                    messageNumber="04"
                    [text] = "texts['office']"
                ></text-bloc>
            </div>
        </div>
    </div>
</div>

<div class="slide">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-9">
                <img-app src="assets/img/office/6.jpg" class="rounded"></img-app>
            </div>
        </div>
    </div>
</div>

<div class="slide">
    <div class="container">
        <div class="row">
            <div class="col-12 col-sm-6 my-3" *ngFor="let i of [1,2,3,5]">
                <img-app src="assets/img/office/{{i}}.jpg" class="rounded"></img-app>
            </div>
        </div>
    </div>
</div>
