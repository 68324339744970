<div class="slide">
    <div class="container">
        <div class="row">
            <div class="col-3">
                <titre
                    name="Heavy Industry"
                    logiciel="Blender"
                    right="true"
                ></titre>
                <div class="mb-5"></div>
                <text-bloc
                    messageNumber="02"
                    [text] = "texts['industry']"
                ></text-bloc>
            </div>
            <div class="col-9">
                <img-app src="assets/img/industry/5.jpg" class="rounded"></img-app>
            </div>
        </div>
    </div>
</div>

<div class="slide">
    <div class="container">
        <div class="row">
            <div class="col-6 my-3" *ngFor="let i of [1,2,3,4]">
                <img-app src="assets/img/industry/{{i}}.jpg" class="rounded"></img-app>
            </div>
        </div>
    </div>
</div>
