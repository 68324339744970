<!-- <div id="home" class="animate__animated">
    <app-home *ngIf="!continued" (continue)="continue()"></app-home>
</div> -->

<div class="topbar animate__animated animate__fadeIn">
    <div class="site-title">Tritschler Tanguy</div>
    <nav id="menu" *ngIf="continued" class="animate__animated animate__fadeIn">
        <a class="menu-links" data-menuanchor="welcome" href="#welcome">Welcome</a>
        <a class="menu-links" data-menuanchor="industry" href="#industry">Heavy industry</a>
        <a class="menu-links" data-menuanchor="shining" href="#shining">Shining</a>
        <a class="menu-links" data-menuanchor="office" href="#office">Project office</a>
        <a class="menu-links" data-menuanchor="duality" href="#duality">Duality</a>
        <a class="menu-links" data-menuanchor="house" href="#house">Tiny house</a>
        <a class="menu-links" data-menuanchor="divers" href="#divers">Divers</a>
        <a class="menu-links" data-menuanchor="contact" href="#contact">Contact</a>
    </nav>
</div>

<app-content *ngIf="continued"></app-content>
<div class="bg"></div>
<app-particles></app-particles>
