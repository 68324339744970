import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoaderComponent } from './loader/loader.component';
import { SvgComponent } from './svg/svg.component';
import { ContentComponent } from './content/content.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { IndustryComponent } from './pages/industry/industry.component';
import { ShiningComponent } from './pages/shining/shining.component';
import { OfficeComponent } from './pages/office/office.component';
import { DualityComponent } from './pages/duality/duality.component';
import { HouseComponent } from './pages/house/house.component';
import { TextBlocComponent } from './text-bloc/text-bloc.component';
import { TitreComponent } from './titre/titre.component';
import { ImgComponent } from './img/img.component';
import { DiversComponent } from './pages/divers/divers.component';
import { ParticlesComponent } from './particles/particles.component';
import { ContactComponent } from './pages/contact/contact.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        LoaderComponent,
        SvgComponent,
        ContentComponent,
        WelcomeComponent,
        IndustryComponent,
        ShiningComponent,
        OfficeComponent,
        DualityComponent,
        HouseComponent,
        TextBlocComponent,
        TitreComponent,
        ImgComponent,
        DiversComponent,
        ParticlesComponent,
        ContactComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
