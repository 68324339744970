import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    public text = `
        <p>Email : <a href="mailto:tanguy.tritschler@gmail.com">tanguy.tritschler@gmail.com</a></p>
        <p class="mb-0">Téléphone : <a href="tel:0751081636">07 51 08 16 36</a></p>
        <!--<p class="mb-0">Lettre de motivation : <a target="_blank" href="/assets/lettre_motivation_Tritschler_Tanguy.pdf">Télécharger</a></p>-->
    `

    constructor() { }

    ngOnInit(): void {
    }

}
