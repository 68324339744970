import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'titre',
    templateUrl: './titre.component.html',
    styleUrls: ['./titre.component.scss']
})
export class TitreComponent implements OnInit {

    @Input() name = null
    @Input() logiciel = null
    @Input() right = false
    @Input() height = '30px'

    constructor() { }

    ngOnInit(): void {

    }

}
