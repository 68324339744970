import { Component, OnInit, AfterViewInit, Output, EventEmitter } from '@angular/core';

declare var $:any

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

    @Output() continue = new EventEmitter<string>()

    public loading = true

    public canPass = false

    public strings = [
        "Welcome user 44306",
        "Loading mind 1197",
        "Dn made espace",
        "Loading complete"
    ]

    constructor() { }

    ngOnInit(): void {
        if(localStorage.getItem('canPass')){
            this.canPass = localStorage.getItem('canPass') == 'true'
        }
    }

    ngAfterViewInit(){
        const resolver = {
            resolve: (options, callback)=>{
                const resolveString =
                options.resolveString ||
                options.element.getAttribute("data-target-resolver");
                const combinedOptions = Object.assign({}, options, { resolveString: resolveString });

                var randomCharacter = (characters)=>{
                    return characters[Math.floor(Math.random()*characters.length)];
                }

                var doRandomiserEffect = (options, callback)=>{
                    const characters = options.characters;
                    const timeout = options.timeout;
                    const element = options.element;
                    const partialString = options.partialString;

                    let iterations = options.iterations;

                    setTimeout(() => {
                        if (iterations >= 0) {
                            const nextOptions = Object.assign({}, options, { iterations: iterations - 1 });

                            if (iterations === 0) {
                                element.textContent = partialString;
                            } else {
                                element.textContent = partialString.substring(0, partialString.length - 1) + randomCharacter(characters);
                            }

                            doRandomiserEffect(nextOptions, callback);
                        } else if (typeof callback === "function") {
                            callback();
                        }
                    }, options.timeout);
                }

                var doResolverEffect = (options, callback)=>{
                    const resolveString = options.resolveString;
                    const characters = options.characters;
                    const offset = options.offset;
                    const partialString = resolveString.substring(0, offset);
                    const combinedOptions = Object.assign({}, options, { partialString: partialString });


                    doRandomiserEffect(combinedOptions, () => {
                        const nextOptions = Object.assign({}, options, { offset: offset + 1 });

                        if (offset <= resolveString.length) {
                            doResolverEffect(nextOptions, callback);
                        } else if (typeof callback === "function") {
                            callback();
                        }
                    });
                }
                doResolverEffect(combinedOptions, callback);
            }
        };

        let counter = 0;

        const options = {
            offset: 0,
            timeout: 1,
            iterations: 10,
            characters: 'abcdefghijklmnopqrstuvxyx0123456789#%&-+_?/\\=',
            resolveString: this.strings[counter],
            element: document.querySelector("[data-target-resolver]"),
        };

        var callback = ()=>{
            setTimeout(() => {
                counter++;
                if (counter < this.strings.length) {
                    let nextOptions = Object.assign({}, options, { resolveString: this.strings[counter] });
                    resolver.resolve(nextOptions, callback);
                    if(counter == this.strings.length-1){
                        setTimeout(() => {
                            $('#loader').addClass('animate__zoomOut')
                            setTimeout(() => {
                                this.loading = false
                                this.canPass = false
                                localStorage.setItem('canPass', 'true')
                                $('#continue').css('opacity', 1, 'important')
                            }, 500);
                        }, 750);
                    }
                }
            }, 1500);
        }

        resolver.resolve(options, callback);
    }

}
