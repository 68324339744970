import { Component, OnInit } from '@angular/core';

import { texts } from '../../texts'

@Component({
    selector: 'app-duality',
    templateUrl: './duality.component.html',
    styleUrls: ['./duality.component.scss']
})
export class DualityComponent implements OnInit {

    public texts = texts

    constructor() { }

    ngOnInit(): void {
    }

}
