<div class="container">
    <div class="row justify-content-center">
        <div class="col-9">
            <text-bloc
                messageNumber="03"
                [text] = "texts['shining']"
            ></text-bloc>
            <div class="mt-4 ratio ratio-16x9 rounded overflow-hidden">
                <iframe src="https://www.youtube.com/embed/mc8zcdpDdQQ" title="YouTube video" frameborder="0" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</div>
