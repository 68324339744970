<div class="content animate__animated animate__fadeIn">
    <div class="text infrontof-particles">
        <div data-target-resolver></div>
    </div>
    <div class="mt-4 loader">
        <div id="loader" class="animate__animated w-100" *ngIf="loading">
            <app-loader></app-loader>
        </div>
        <button class="btn btn-primary animate__animated animate__bounceIn" *ngIf="!loading" (click)="continue.emit()">Continuer</button>
    </div>
</div>

<div class="canPass text-primary animate__animated animate__fadeIn" *ngIf="canPass" (click)="continue.emit()"><span class="cursor-pointer">Passer l'intro</span></div>
