<div class="container">
    <div class="row justify-content-center">
        <div class="col-9">
            <text-bloc
                messageNumber="05"
                [text] = "texts['duality']"
            ></text-bloc>
            <div class="ratio ratio-16x9 rounded overflow-hidden mt-4">
                <iframe src="https://www.youtube.com/embed/lBCzah6hVJ8" title="YouTube video" frameborder="0" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</div>
