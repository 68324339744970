<div class="container text-left text-dark contact">
    <div class="row justify-content-center">
        <div class="col-5">
            <text-bloc
                messageNumber="10"
                [text] = "text"
            ></text-bloc>
        </div>
    </div>
</div>
