<div class="slide">
    <div class="container">
        <text-bloc
            messageNumber="07"
            [text] = "texts['divers']['fablab']"
        ></text-bloc>
        <div class="row justify-content-center mt-5">
            <div class="col-4 text-center" *ngFor="let i of ['overwatch', 'bastion', 'catane']">
                <img-app src="assets/img/divers/fablab/{{i}}.jpg" class="rounded"></img-app>
            </div>
        </div>
    </div>
</div>

<div class="slide">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-9 mb-5">
                <text-bloc
                    messageNumber="08"
                    [text] = "texts['divers']['dessin']"
                ></text-bloc>
            </div>
            <div class="col-9 text-center">
                <img-app src="assets/img/divers/dessin.jpg" class="rounded"></img-app>
            </div>
        </div>
    </div>
</div>

<div class="slide">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-10 mb-5">
                <text-bloc
                    messageNumber="09"
                    [text] = "texts['divers']['gunpla']"
                ></text-bloc>
            </div>
            <div class="col-10 text-center d-flex justify-content-center">
                <div class="gundam-grid">
                    <img-app src="assets/img/divers/gunpla/1.jpg" class="rounded h-100"></img-app>
                    <div class="h-100 d-flex flex-column ms-3 align-items-center justify-content-center">
                        <img-app src="assets/img/divers/gunpla/2.jpg" class="rounded he-50" clazz="h-100"></img-app>
                        <div class="pt-3"></div>
                        <img-app link="https://www.reddit.com/r/Gunpla/comments/ldbyci/hg_1144_gundam_astaroth_rinascimento_diorama/" src="assets/img/divers/gunpla/5.jpg" class="rounded he-50" clazz="h-100"></img-app>
                    </div>
                    <div class="h-100 d-flex flex-column ms-3">
                        <img-app src="assets/img/divers/gunpla/3.jpg" class="rounded he-50" clazz="h-100"></img-app>
                        <div class="pt-3"></div>
                        <img-app link="https://www.reddit.com/r/Gunpla/comments/6ldz6w/only_14_months_later_my_armored_core_maintenance/" src="assets/img/divers/gunpla/6.jpg" class="rounded he-50" clazz="h-100"></img-app>
                    </div>
                    <div class="h-100 d-flex flex-column ms-3">
                        <img-app src="assets/img/divers/gunpla/4.jpg" class="rounded he-50" clazz="h-100"></img-app>
                        <div class="pt-3"></div>
                        <img-app link="https://www.reddit.com/r/Gunpla/comments/lourj8/my_latest_work_1100_barbatos_lupus_diorama/" src="assets/img/divers/gunpla/7.jpg" class="rounded he-50" clazz="h-100"></img-app>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
