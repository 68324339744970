import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'img-app',
    templateUrl: './img.component.html',
    styleUrls: ['./img.component.scss']
})
export class ImgComponent implements OnInit {

    @Input() src = null
    @Input() class = ''
    @Input() clazz = ''
    @Input() clickable = true
    @Input() link = null

    public target = '#'

    constructor() { }

    ngOnInit(): void {
        this.target = ((this.link) ? this.link : '/'+this.src)
    }

}
