<div class="bloc" [ngStyle]="{'height' : height}" [ngClass]="{'bloc-right' : right}">

    <div class="borders">
        <div class="top"></div>
        <div class="bottom"></div>
        <div class="right" *ngIf="right"></div>
        <div class="left" *ngIf="!right"></div>
        <img src="/assets/svg/titre.svg" alt="">
        <div class="bg1"></div>
    </div>

    <div class="texts">
        <div *ngIf="name">{{name}}</div>
        <div *ngIf="logiciel">Logiciel : {{logiciel}}</div>
    </div>
</div>
