<div class="container">
    <div style="width:300px" class="text-center m-auto">
        <img-app [clickable]="false" src="assets/img/welcome/brain.svg" alt="brain"></img-app>
    </div>

    <div class="text-primary h2 mb-3">Welcome in my mind</div>
    <text-bloc
        messageNumber="01"
        [text] = "texts['welcome']"
        ></text-bloc>
</div>
