export const texts = {
    welcome : `Attiré depuis toujours par les différentes formes d’art : visuel et musical, mais également passionné par l'informatique et ses outils de création.  Curieux et avide de savoir-faire, je me suis procuré différents logiciels de création afin d’explorer ma créativité. Illustrator, After effects, Blender ou bien Ableton ont été mes hobbies principaux ces dernières années. Je n’ai suivi aucune formation, tout mon savoir-faire a été acquis en autodidacte ainsi qu'à l'aide de ressources disponibles sur internet. J'ai réalisé différents projets seul ou en collaborant avec des ami(e)s, tout en apprenant de mes erreurs et en perfectionnant mes techniques afin d’être plus efficace.`,
    industry : `Réalisé en m’inspirant de l’univers Cyberpunk, un futur alternatif où des usines démesurées surplombent les villes. Créé grâce au logiciel open source Blender. Vous trouverez le processus de création en cliquant sur la flèche à droite. Le but de ce projet est d’utiliser les jeux de lumière et les véhicules pour donner vie au rendu.`,
    shining : `Projet d'un vizualizer sur le thème des abysses commandé par un ami. Vous pouvez le retrouver sur Youtube et Soundcloud sous le nom de Dawko. C’est le premier projet que j’ai réalisé à la suite d’une demande. J’ai apprécié travailler pour lui, cela m’a permis de découvrir le travail avec ses contraintes, contrairement à mes autres projets dans lesquels j’étais le seul décideur.`,
    office : `Conçu en pensant à mon futur bureau, celui-ci se devait d’être l’élément central. Premier projet de design intérieur réalisé avec Blender. La recherche du mobilier ainsi que la conception sur-mesure de certains éléments étaient intéressantes, tout comme la réflexion de l’agencement.`,
    duality : `Vidéo réalisée avec After Effects, inspirée par la série Sabrina sur Netflix. J’ai voulu représenter la dualité entre le bien et le mal.<br>Attention, la vidéo comporte des flashs lumineux répétitifs.`,
    house : `Essai à la conception d’une « tiny house » au milieu des bois. Une résidence secondaire pour un adulte cherchant à retrouver la nature lors de ses temps libres, loin de la ville. Un espace réfléchi pour deux personnes avec une vue constante sur la vie de la forêt.`,
    divers : {
        fablab : `Projet réalisé avec les compétences acquises durant mon service civique dans un FabLab, utilisant Illustrator pour les plans de découpe et la découpe laser.`,
        dessin : `Exemple de dessin conçu avec trois ou deux points de fuite. Ainsi qu’un projet sur le thème de la guerre visant à sensibiliser sur la violence présente.`,
        gunpla : `Présentation des maquettes gundam que j’ai assemblées afin de débuter dans l’univers du gunpla avec ci-joint des exemples d’artistes qui m’inspirent ce hobbie.`
    }
};
