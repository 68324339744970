import { Component, OnInit, Input } from '@angular/core';

import { texts } from '../../texts'

@Component({
    selector: 'app-office',
    templateUrl: './office.component.html',
    styleUrls: ['./office.component.scss']
})
export class OfficeComponent implements OnInit {

    public texts = texts

    constructor() { }

    ngOnInit(): void {
    }

}
