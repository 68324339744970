import { Component, OnInit } from '@angular/core';

declare var $:any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    public continued = false

    constructor(){
        this.continue()
    }

    continue(){
        // $('#home').addClass('animate__fadeOut')
        // setTimeout(() => {
            this.continued = true
        // }, 500);
    }

    ngOnInit(): void {
        
    }

}
