<div class="bloc">
    <div class="borders">
        <img class="bottom-left" src="assets/svg/box-bottom-left.svg" alt="">
        <img class="bottom-right" src="assets/svg/box-bottom-right.svg" alt="">
        <img class="top-left" src="assets/svg/box-top-left.svg" alt="">
        <img class="top-right" src="assets/svg/box-top-right.svg" alt="">
        <div class="bottom"></div>
        <div class="left"></div>
        <div class="right"></div>
        <div class="top"></div>

        <div class="bgs">
            <div class="bg1"></div>
            <div class="bg2"></div>
            <div class="bg3"></div>
        </div>

    </div>
    <div class="message-number" *ngIf="messageNumber">
        Message n°{{messageNumber}}
    </div>
    <div class="text" [innerHtml]="text"></div>
</div>
