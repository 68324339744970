import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'text-bloc',
    templateUrl: './text-bloc.component.html',
    styleUrls: ['./text-bloc.component.scss']
})
export class TextBlocComponent implements OnInit {

    @Input() messageNumber = null

    @Input() text = ''

    constructor() { }

    ngOnInit(): void {
    }

}
